import { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

// * Tab label ?

// * All these class names were pulled from the typography styleguide that is zeplin
// TODO Im not sure what the 'stat' class is supposed to be applied to, right now it exists on all heading levels. The todo is to find out what it should be applied to

export default css`
  body {
    font-family: ${themeGet('fonts.roboto')};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${themeGet('fonts.alatsi')};
    color: ${themeGet('colors.black')};

    &.stat {
      font-weight: ${themeGet('fontWeights.bold')};
      font-size: 52px;
      line-height: 64px;
      letter-spacing: -0.3px;
    }
  }

  h1 {
    font-weight: ${themeGet('fontWeights.extraBold')};
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -0.2px;
  }

  h2 {
    font-weight: ${themeGet('fontWeights.extraBold')};
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.15px;

    &.large {
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -0.15px;
    }
  }

  h3 {
    font-weight: ${themeGet('fontWeights.bold')};
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0px;
  }

  h4 {
    font-weight: ${themeGet('fontWeights.bold')};
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0px;

    &.card {
      font-size: 20px;
    }
  }

  h5 {
    font-weight: ${themeGet('fontWeights.bold')};
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0px;
  }

  h6 {
  } // * No provided styles for h6

  p,
  span {
    font-weight: ${themeGet('fontWeights.regular')};
    color: ${themeGet('colors.black')};
    letter-spacing: 0px;
    &.large {
      font-size: 18px;
      line-height: 24px;
    }
    &.small {
      font-size: 14px;
      line-height: 22px;
    }
    &.tiny {
      font-size: 10px;
      line-height: 16px;
      color: ${themeGet('colors.grey')};
    }
    &.label {
      font-size: 14px;
      line-height: 32px;
    }
    &.tab {
      font-size: 14px;
      line-height: 18px;
    }
  }

  a {
    color: ${themeGet('colors.blue')};
    font-weight: ${themeGet('fontWeights.semiBold')};
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;

    &:hover {
      color: ${themeGet('colors.blues.1')};
    }
  }

  ul {
  }

  li {
  }

  @media only screen and (min-width: ${themeGet('breakpoints.md')}) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &.stat {
        font-size: 64px;
        line-height: 72px;
        letter-spacing: -0.37px;
      }
    }

    h1 {
      font-size: 54px;
      line-height: 60px;
      letter-spacing: -0.3px;
    }

    h2 {
      font-size: 36px;
      line-height: 40px;
      letter-spacing: -0.2px;

      &.large {
        font-size: 42px;
        line-height: 48px;
        letter-spacing: -0.2px;
      }
    }

    h3 {
      font-size: 28px;
      line-height: 32px;
    }

    h4 {
      font-size: 24px;
      line-height: 24px;

      &.card {
        font-size: 24px;
        line-height: 24px;
      }
    }

    h5 {
      font-size: 20px;
      line-height: 24px;
    }

    p,
    span {
      &.large {
      } // * No Style Changes
      &.small {
      } // * No Style Changes
      &.tiny {
        font-size: 12px;
        line-height: 18px;
      }
      &.label {
        font-size: 16px;
        line-height: 32px;
      }
      &.tab {
      } // * No Style Changes
    }
  }

  @media only screen and (min-width: ${themeGet('breakpoints.lg')}) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &.stat {
        font-size: 72px;
        line-height: 64px;
        letter-spacing: -0.3px;
      }
    }

    h1 {
      font-size: 102px;
      line-height: 106px;
      letter-spacing: -0.5px;
    }

    h2 {
      font-size: 42px;
      line-height: 54px;
      letter-spacing: -0.25px;

      &.large {
        font-size: 52px;
        line-height: 56px;
        letter-spacing: -0.31px;
      }
    }

    h3 {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.25px;
    }

    h4 {
      font-size: 28px;
      line-height: 28px;

      &.card {
        font-size: 28px;
        line-height: 28px;
      }
    }

    h5 {
      font-size: 24px;
      line-height: 32px;
    }

    p,
    span {
      &.large {
        font-size: 21px;
        line-height: 28px;
      }
      &.small {
        font-size: 16px;
        line-height: 24px;
      }
      &.tiny {
        font-size: 10px;
        line-height: 16px;
      }
      &.label {
        font-size: 18px;
        line-height: 32px;
      }
      &.tab {
      } // * No Style Changes
    }
  }
`;
