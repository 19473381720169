import React from 'react';
import PropTypes from 'prop-types';
import propTypes from '@styled-system/prop-types';

import { Wrapper, Grid } from './styles';

const Container = ({ children, as, id, className, bg }) => {
  if (bg) {
    return (
      <Wrapper id={id} bg={bg}>
        <Grid as={as} className={className}>
          {children}
        </Grid>
      </Wrapper>
    );
  }
  return (
    <Grid id={id} as={as} className={className}>
      {children}
    </Grid>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  ...propTypes.background,
};

Container.defaultProps = {
  className: null,
};

export default Container;
