import React from 'react';
import PropTypes from 'prop-types';

import { StyledLink } from './styles';

const Link = ({ children, to, className, icon }) => {
  return (
    <StyledLink to={to} className={className} icon={icon}>
      {children}
    </StyledLink>
  );
};

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
};

Link.defaultProps = {
  to: '/',
  className: null,
  icon: null,
};

export default Link;
