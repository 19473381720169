import React, { useState, useEffect } from 'react';

import { Progress } from './styles';

const ScrollProgressBar = () => {
  const [scroll, setScroll] = useState(0);

  const getDocHeight = () => {
    if (document !== 'undefined') {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight,
      );
    }
    return true;
  };

  const calculateScrollDistance = () => {
    if (window !== 'undefined') {
      const scrollTop = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const docHeight = getDocHeight();

      const totalDocScrollLength = docHeight - windowHeight;
      const scrollPostion = Math.floor(
        (scrollTop / totalDocScrollLength) * 100,
      );
      setScroll(scrollPostion);
    }
  };

  const requestScrollFrame = () => {
    if (window !== 'undefined') {
      window.requestAnimationFrame(() => {
        // Calculates the scroll distance
        calculateScrollDistance();
      });
    }
  };

  const listenToScrollEvent = () => {
    if (document !== 'undefined')
      document.addEventListener('scroll', requestScrollFrame);
  };

  const removeScrollEvent = () => {
    if (document !== 'undefined')
      document.removeEventListener('scroll', requestScrollFrame);
  };

  useEffect(() => {
    listenToScrollEvent();
    return () => {
      removeScrollEvent();
    };
  }, []);

  return <Progress scroll={`${scroll}%`} />;
};

export default ScrollProgressBar;
