import styled from 'styled-components';

export const StyledHeader = styled.header`
  &.portrait {
    position: fixed;
    top: 0;
    height: 40px;
    z-index: 11;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Logo = styled.img`
  max-height: 100px;
  max-width: 200px;
  object-fit: contain;
  rotate: 90deg;
`;

export const Controls = styled.ul`
  margin-left: 100px;
  flex: 1 1 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;
