/* eslint-disable prefer-destructuring */
const theme = {
  borders: {},
  borderWidths: [],
  borderStyles: [],
  radii: [],
  breakpoints: ['374px', '768px', '1279px'],
  media: {
    grid: '1280px',
    'grid-gutter': ['10px', '20px', '30px', '60px'],
  },
  colors: {
    red: 'red',
    reds: [''],
    black: '#000000',
    grey: 'grey',
    greys: [''],
    white: '#FFFFFF',
    whites: ['#FFFFFF', '#e9eaeb'],
    yellow: 'yellow',
    blue: 'blue',
    blues: [''],
  },
  fonts: {
    roboto: `Roboto`,
    alatsi: `Alatsi`,
  },
  fontSizes: [],
  fontWeights: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  lineHeights: [],
  letterSpacings: [],
  textDecoration: {},
  space: ['10px', '20px', '30px'],
  sizes: [],
  shadows: {},
  transitions: [],
  zIndices: [0, 1, 2, 3, 6, 12, 24, 48, 96],
};

theme.breakpoints.sm = theme.breakpoints[0];
theme.breakpoints.md = theme.breakpoints[1];
theme.breakpoints.lg = theme.breakpoints[2];

export default theme;
