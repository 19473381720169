import React from 'react';
import PropTypes from 'prop-types';

import Container from '../Container';
import Link from '../Link';

import {
  StyledFooter,
  Wrapper,
  ContactWrapper,
  Contact,
  Logo,
  Social,
} from './styles';

const Footer = ({ data }) => {
  if (!data) return null;
  const { logo, links } = data;
  return (
    <StyledFooter>
      <Container className="auto-margin">
        <Wrapper>
          <ContactWrapper>
            <div>
              <h1>SAY HELLO</h1>
              <Social>
                <li>Twitter</li>
                <li>Instagram</li>
                <li>Dribble</li>
              </Social>
            </div>
            <Contact>
              <p>hello@chrisbridgeman.com</p>
              <p>416-427-5616</p>
            </Contact>
          </ContactWrapper>
          {/* <ul>
            {links &&
              links.map(({ text, href, style }) => {
                return (
                  <li>
                    <Link to={href} className={style}>
                      {text}
                    </Link>
                  </li>
                );
              })}
          </ul> */}
        </Wrapper>
      </Container>
    </StyledFooter>
  );
};

Footer.propTypes = {
  data: PropTypes.shape().isRequired,
};

Footer.defaultProps = {};

export default Footer;
