/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import theme, { GlobalStyles } from '../../../themes';
import Header from '../Header';
import Footer from '../Footer';
import ScrollProgressBar from '../ScrollProgressBar';

import { Main } from './styles';

const Layout = ({ children, header, footer }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <ScrollProgressBar />
        {header && <Header data={header} />}
        <Main>{children}</Main>
        {footer && <Footer data={footer} />}
      </>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.shape(),
  footer: PropTypes.shape(),
};

Layout.defaultProps = {
  header: null,
  footer: null,
};

export default Layout;
