import React from 'react';
import PropTypes from 'prop-types';

import Container from '../Container';
import Link from '../Link';

import { StyledHeader, Wrapper, Logo, Controls } from './styles';

const Header = ({ data }) => {
  if (!data) return null;
  const { logo, links } = data;
  return (
    <StyledHeader>
      <Container className="auto-margin">
        <Wrapper>
          <Link to="/">
            <Logo src={logo.localFile.publicURL} alt="" />
          </Link>
          <Controls>
            {links &&
              links.map(({ text, href, style }) => {
                return (
                  <li>
                    <Link to={href} className={style}>
                      {text}
                    </Link>
                  </li>
                );
              })}
          </Controls>
        </Wrapper>
      </Container>
    </StyledHeader>
  );
};

Header.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Header;
