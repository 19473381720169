import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledFooter = styled.footer`
  background-color: ${themeGet('colors.black', 'black')};
  padding: 50px 0;
`;

export const Wrapper = styled.div`
  color: ${themeGet('colors.white', 'white')};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  h1,
  p {
    color: inherit;
  }
`;

export const ContactWrapper = styled.div``;

export const Logo = styled.img`
  max-height: 100px;
  max-width: 200px;
  object-fit: contain;
  rotate: 90deg;
`;

export const Social = styled.ul`
  margin-left: 100px;
  flex: 1 1 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const Contact = styled.ul`
  flex: 1 1 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* justify-content: space-between; */
`;
