import styled from 'styled-components';
import { color } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  ${color};
`;

export const Grid = styled.div`
  position: relative;
  /* margin: 0 auto; */
  margin: 40px auto;
  max-width: ${themeGet('media.grid')};
  // * calc(100% - 30px) gives us 15px margin on the left and right of the grid container on a mobile view
  width: calc(100% - ${themeGet('media.grid-gutter.2')});
  // * calc(100% - 60px) gives us 30px margin on the left and right of the grid container on a tablet view
  @media only screen and (min-width: ${themeGet('breakpoints.md')}) {
    width: calc(100% - ${themeGet('media.grid-gutter.3')});
    margin: 70px auto;
  }
  @media only screen and (min-width: ${themeGet('breakpoints.lg')}) {
    margin: 100px auto;
  }

  &.auto-margin {
    margin: 0 auto;
  }
`;
